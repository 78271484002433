
import Vue from 'vue'
import { isNotNested, parseData, parseForm } from '../../mixins/formData'

export default Vue.extend({
  name: 'ProjektTermin',

  data: () => {
    const formData: any = {}
    const project: any = {}
    return {
      dots: '.',
      errors: [],
      formData,
      formObj: {},
      interv: 0,
      isLoading: true,
      project,
    };
  },

  computed: {
    awardConf () {
      return this.$store.state.currentAwardConf
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part || this.$store.state.currentAwardConf.forms.viewing_appointment.parts[0].key
    },
    formParts () {
      if (!this.$store.state.currentAwardConf) return null
      return this.$store.state.currentAwardConf.forms.viewing_appointment.parts
    },
    id () {
      return this.$route.params.id;
    },
    pdfDocuments(): any {
      const { awardType, year } = this.currentAward
      if (this.$store.state.appInfo?.awards?.pdfDocuments[awardType]) {
        return this.$store.state.appInfo?.awards?.pdfDocuments[awardType][year]
      } else {
        return null
      }
    },
    projectCategories () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.meta.projectCategories : null
    },
    projectDetail () {
      const res = this.project.projectDetails.filter((pd: any) => {
        return pd.awardType === this.currentAward.awardType && pd.year === this.currentAward.year
      })
      return res[0]
    },
  },

  methods: {
    animateDots () {
      if (this.dots.length <= 4) {
        this.dots += '.';
      } else {
        this.dots = '.';
      }
    },

    getPdfPath(p: any, type: string) {
      const { awardType, year } = this.currentAward
      if (!this.pdfDocuments) return ''
      let url = this.baseUrl.slice(0, -1) + this.pdfDocuments[type].url
      url = url.replace('-AWARDTYPE-', awardType)
      url = url.replace('-YEAR-', year)
      url = url.replace('-ID-', p.id)
      return url
    },

    initProject() {
      this.$store
        .dispatch('getProjectItem', this.$route.params.id)
        .then((project: any) => {
          this.project = project
          const { formData } = this.parseData(this.formParts, Object.assign({}, this.project, this.projectDetail), this.formData)
          this.formObj = project
          this.formData = Object.assign({}, this.formData, formData)
          if (project.strasse && !this.formData[this.currentPart + '/viewing_appointment_strasse'])  {
            this.formData[this.currentPart + '/viewing_appointment_strasse'] = project.strasse
          }
          if (project.ort && !this.formData[this.currentPart + '/viewing_appointment_ort']) {
            this.formData[this.currentPart + '/viewing_appointment_ort'] = project.ort
          }
          if (project.plz && !this.formData[this.currentPart + '/viewing_appointment_plz']) {
            this.formData[this.currentPart + '/viewing_appointment_plz'] = project.plz
          }
          this.isLoading = false
        });
    },

    async saveAppointment() {
      this.isLoading = true
      if (this.interv) clearInterval(this.interv)
      this.interv = window.setInterval(this.animateDots, 200);
      const { awardType, year } = this.currentAward
      const formName = 'viewing_appointment'
      let formData = this.parseForm(this.formParts, this.formData)[this.currentPart]
      let appointment: any = {
        formData,
        formName,
        awardType,
        year,
        id: this.id,
        part: this.currentPart,
      }

      this.$store.dispatch('saveProject', appointment).then(() => {
        this.isLoading = false
        if (this.interv) clearInterval(this.interv)
        this.$router.push('/anmelden/index')
      }).catch((err: Error) => {
        console.log('saveAppointment -> err', err)
        this.isLoading = false
        if (this.interv) clearInterval(this.interv)
        const errors = JSON.parse(err.message)
        this.errors = errors.errorObject
        this.$store.commit('setAlert', {
          type: 'error',
          message: 'Fehler beim übermitteln! Bitte prüfen Sie die Eingaben.',
          cb: () => {
            const errors = document.querySelectorAll('.form-field .error')
            let el: any = errors[0]
            if (el) {
              let top = 0;
              while (el) {
                top += el.offsetTop
                el = el.offsetParent
              }
              window.scrollTo({top: top - 20, behavior: 'smooth'})
            }
          }
        })
      })
    return false
    },

    isNotNested,
    parseData,
    parseForm,
  },

  mounted () {
    this.initProject();
  },

  destroyed () {
    if (this.interv) {
      clearInterval(this.interv)
    }
  },

});
